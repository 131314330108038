import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import userActions from "../../Redux/user/actions";
import { DecryptFunction } from "../../utils/cryptoFunction";
import { useTranslation } from 'react-i18next';
import noProfileImg from '../../assets/images/noprofile.png';
import { AlphabetsValidation, MobileNumberValidation, handleEmailValidationWithoutRequired } from "../../utils/validation";
import { LogEvent } from "../../utils/firebase";

export default function Profile(props) {

    const { handleProfileImgUpdate } = props;

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { UserProfile } = useSelector(state => state.AuthReducer);

    const { buttonLoader } = useSelector(state => state.commonReducer);

    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const [showUpdate, setShowUpdate] = useState(false);

    useEffect(() => {
        if (UserProfile) {
            const data = {
                name: UserProfile?.name,
                email: UserProfile?.email,
                age: UserProfile?.age,
                gender: UserProfile?.gender,
                alternate_no: UserProfile?.alternate_no
            };
            reset(data);
        };
        //eslint-disable-next-line
    }, [UserProfile]);

    const onSubmit = (data) => {
        LogEvent('Profile_Update_Clicked')
        let formData = { ...UserProfile, ...data };
        let finalObj = Object.entries(formData).reduce((acc, [k, v]) => v ? { ...acc, [k]: v } : acc, {})
        const id = DecryptFunction(localStorage.getItem('auth_user'));
        if (id) {
            dispatch({ type: userActions.UPDATE_USER_PROFILE, payload: { data: finalObj, id: id } });
        }
        setShowUpdate(false);
    };

    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-5">
                <div className="d-flex align-items-center">
                    <div className="user-profile-page-profile-img">
                        <img src={UserProfile?.img_url || noProfileImg} alt="" className="user-profile-page-profile" />
                        <input
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            id="contained-button-file"
                            onChange={handleProfileImgUpdate}
                        />
                        <label htmlFor="contained-button-file">
                            <div className="user-profile-edit-icon">{t("Edit")}</div>
                        </label>
                    </div>
                    <div className="ms-3">
                        <h5 className="user-profile-page-welcome">Welcome <span className="user-profile-page-name">{UserProfile?.name || "Guest"}</span></h5>

                        <h6>{UserProfile?.mobile_no}</h6>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <Row className="user-profile-tab1-details row-padding-remover">
                    <Col lg={6} md={12} className="mb-4">
                        <label className="form-label form-label-custom-style">{t("Name")} <span className="fw-bold text-danger">*</span></label>
                        <input
                            type="text"
                            className="form-control input-bg-none"
                            {...register('name', {
                                required: "Name is required",
                                pattern: {
                                    value: /^[a-zA-Z ]*$/,
                                    message: "Name should only contain alphabets",
                                }
                            })}
                            onInput={AlphabetsValidation}
                        />
                        <small className="text-danger mt-1">{errors?.name?.message}</small>
                    </Col>
                    <Col lg={6} md={12} className="mb-4">
                        <label className="form-label form-label-custom-style">{t("Age")}</label>
                        <input
                            className="form-control input-bg-none"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            {...register('age', {
                                maxLength: {
                                    value: 3,
                                    message: "Enter a valid age",
                                },
                                min: {
                                    value: 1,
                                    message: "Enter a valid age",
                                }
                            })}
                        />
                        <small className="text-danger mt-1">{errors?.age?.message}</small>
                    </Col>
                  
                    <Col lg={6} md={12} className="mb-4">
                        <label className="form-label form-label-custom-style">{t("Gender")}</label>
                        <div className="d-flex">
                            <select
                                className="form-select input-bg-none flex-grow-1 ms-0 "
                                {...register('gender')}
                            >
                                <option value='' disabled>--Select--</option>
                                <option value='Male'>Male</option>
                                <option value='Female'>Female</option>
                                <option value='Other'>Other</option>
                            </select>
                        </div>

                        <small className="text-danger mt-1">{errors?.gender?.message}</small>
                    </Col>

                    <Col lg={6} md={12} className="mb-4">
                        <label className="form-label form-label-custom-style">{t("Email")}</label>
                        <input
                            className="form-control input-bg-none"
                            
                            {...register('email', {
                                validate: handleEmailValidationWithoutRequired
                                // pattern: {
                                //     value: /^[a-zA-Z ]*$/,
                                //     message: "Name should only contain alphabets",
                                // }
                            })}
                        />
                        <small className="text-danger mt-1">{errors?.email?.message}</small>
                    </Col>
                    <Col lg={6} md={12} className="mb-4">
                        <label className="form-label form-label-custom-style">{t("Alternative Phone Number")}</label>
                        <input
                            className="form-control input-bg-none"
                            type="tel"
                            maxLength={10}
                            {...register('alternate_no', {
                                minLength: {
                                    value: 10,
                                    message: "Enter valid mobile number",
                                },
                            })}
                            onInput={MobileNumberValidation}
                        />
                        <small className="text-danger mt-1">{errors?.alternate_no?.message}</small>
                    </Col>
                    <Col md={12}>
                        <div className="text-center">
                            <Button onClick={handleSubmit(onSubmit)}>
                                {buttonLoader ?
                                    <Spinner size="sm" variant="light" /> :
                                    t('Save Profile')
                                }
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

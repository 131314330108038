import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import market_Action from "../../Redux/marketPlace/action";
import Calfrearing from "../../assets/images/consulation/Consultation images/Calf rearing.png"
import Maintenancecow from "../../assets/images/consulation/Consultation images/Maintenance of pregnant cows.png"
import IncreaseMilk from "../../assets/images/consulation/Consultation images/To increase milk production.png"
import GreenFolder from "../../assets/images/consulation/Consultation images/Green Fodder Management.png"
import { IoCallOutline } from "react-icons/io5";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { Container } from "react-bootstrap";
import { PiChatText } from "react-icons/pi";
import { useTranslation } from 'react-i18next';
import { DecryptFunction } from '../../utils/cryptoFunction';
import { LogEvent } from '../../utils/firebase';


const CommonDiseasesPage = () => {

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch({
            type: market_Action.GET_CATEGORY_WISE_LISTINGS_DIARY_CATTLE, payload: 1
        });
    }, [dispatch]);



    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: false,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                },
            },
        ],
        prevArrow: <MdOutlineArrowBackIosNew />,
        nextArrow: <MdOutlineArrowForwardIos />,
    };

    const products = [
        {
            id: 1, image_url: Calfrearing,
            diesase: {
                en: 'Calf rearing',
                tm: 'இலாபம் தரும் கன்று வளர்ப்பு',
                mr: 'वासरांचे संगोपन'
            },
            question: {
                en: "Today's calf is Tomorrow's cow!",
                tm: 'இன்றைய கன்று! நாளைய பசு!',
                mr: 'आजचे वासरू उद्याची गाय आहे!'
            },
            answer: {
                en: "Best farm practice is to produce healthy dairy cows on our farm.",
                tm: 'ஆரோக்கியமான கறவை மாடுகளை நம் பண்ணையிலே உருவாக்குவதே சிறந்த பண்ணை நடைமுறை',
                mr: 'आपल्या शेतात निरोगी दुग्ध गायींचे उत्पादन करणे ही सर्वोत्तम शेती पद्धत आहे.'
            }
        },
        {
            id: 2, image_url: Maintenancecow,
            diesase: {
                en: 'Maintenance of pregnant cows',
                tm: 'கருவில் கன்று ஊக்கமாக வளர',
                mr: 'गाभण गायींची देखभाल'
            },
            question: {
                en: "Need advice on feeding pregnant cows?",
                tm: 'கருவில் உள்ள கன்று வளர கவனத்தில் கொள்ள வேண்டியவை',
                mr: 'गाभण गायींना खायला देण्याबाबत सल्ला हवा आहे?'
            },
            answer: {
                en: "It is important to understand, how to protect pregnant cows from illness and provide the necessary nutrients to the growing calves in the womb.",
                tm: 'கருவில் வளரும் கன்றுகளுக்கு ஊட்டச்சத்து கிடைக்கவும், சினை மாட்டுக்கு நோய் வராமல் பாதுகாப்பதற்கான வழிமுறைகளை தெரிந்து கொள்வது  மிகவும் அவசியம்!',
                mr: 'हे समजून घेणे महत्त्वाचे आहे की, गर्भवती गायींचे आजारपणापासून संरक्षण कसे करावे आणि गर्भाशयात वाढणाऱ्या वासरांना आवश्यक पोषक तत्वे कसे पुरवावेत."'
            }
        },
        {
            id: 3, image_url: IncreaseMilk,
            diesase: {
                en: 'Increase milk production',
                tm: 'மடியில் அதிக பால் உற்பத்தி',
                mr: 'दूध उत्पादन वाढवण्यासाठी'
            },
            question: {
                en: " Increase milk production according to the capacity of dairy cows!",
                tm: 'கறவை மாடுகளின் திறனுக்கேற்ப பால் உற்பத்தியை அதிகரியுங்கள்!',
                mr: 'दुभत्या गायींच्या क्षमतेनुसार दूध उत्पादन वाढवा!'
            },
            answer: {
                en: "Milk production can be increased by feeding each dairy cow according to its production capacity.",
                tm: 'ஒவ்வொரு கறவை மாட்டுக்கும் அதன் உற்பத்தி திறனுக்கு ஏற்ப தீவனம் கொடுப்பதன் மூலம் பால் உற்பத்தியை அதிகரிக்க முடியும்',
                mr: 'प्रत्येक दुभत्या गाईला तिच्या उत्पादन क्षमतेनुसार दूध देऊन दूध उत्पादन वाढवता येते.'
            }
        },
        {
            id: 4, image_url: GreenFolder,
            diesase: {
                en: 'Green Fodder Management',
                tm: 'பசுந்தீவன மேலாண்மை',
                mr: 'हिरवा चारा व्यवस्थापन'
            },
            question: {
                en: "Increase milk production with green fodder!",
                tm: 'கருவில் உள்ள கன்று வளர கவனத்தில் கொள்ள வேண்டியவை',
                mr: 'गाभण गायींना खायला देण्याबाबत सल्ला हवा आहे?'
            },
            answer: {
                en: "Green fodder alone is sufficient to produce up to 4 liters of milk. Providing the right amount of three types of green fodder can increase health and milk production.",
                tm: '4 லிட்டர் வரை பால் உற்பத்தியாவதற்கு பசுந்தீவனமே போதுமானது. மூன்று வகை பசுந்தீவனத்தை சரியான அளவில் கொடுப்பதன் மூலம் ஆரோக்கியத்தையும், பால் உற்பத்தியையும் அதிகரிக்க முடியும்.',
                mr: 'एकटा हिरवा चारा 4 लिटरपर्यंत दूध उत्पादनासाठी पुरेसा आहे. तीन प्रकारचा हिरवा चारा योग्य प्रमाणात दिल्यास आरोग्य आणि दूध उत्पादन वाढू शकते."'
            },
        },]
    return (
      
        <div className="container-fluid mt-2"
            style={{ backgroundColor: "#ededed" }}>
            <Container>
                <div className="treatment_div_cls mb-3 mt-custom ">
                    {t("Common Consultation")}
                
                <Slider {...settings} className="slider-arrow-custom-style-for-related-products">
                    {products?.slice(0, 10)?.map((product, index) => (
                        <div key={index} className="consultation-diseases-card cursor-pointer">
                            
                            <img
                                src={product.image_url}
                                className="consult-common-disease-img rounded"
                                alt={`Product ${index + 1}`}
                            />
                            <div className="consulattion-text " style={{flex:1}}>
                                <h5 className="alignText" style={{ color: '#232c63', textAlign: 'left', fontSize: '17px', margin: "1rem 0",fontWeight:'500' }}>{product.diesase[i18n.language]}</h5>

                                <div>
                                    <div className="limited-question-text" style={{ color: 'black', fontSize: '15px' }}>
                                        {product.question[i18n.language]}
                                    </div>
                                    <div className="limited-text-in-3-line" style={{ color: '#232c63', fontSize: '14px' }}>
                                        {product.answer[i18n.language]}
                                    </div>
                                </div>
                            </div>
                            <div className="CommonDieasesscustom-buttons">
                                <button type="button" className="CommonDieasesschat-button">
                                    <a
                                        href={`https://api.whatsapp.com/send/?phone=916383717150&text=${t('HOME.whatsApp')}&type=phone_number&app_absent=0`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >

                                        <PiChatText style={{ color: 'rgba(35, 44, 99, 1)' }} /> <span style={{ color: 'rgba(35, 44, 99, 1)' }}>{t("Chat")}</span>
                                    </a>
                                </button>
                                <button type="button" className='CommonDieasesscall-button'>
                                    <IoCallOutline /> 
                                    <span onClick={() => {
                                                    const phoneNumber = DecryptFunction(sessionStorage.getItem('pilot_no'));
                                                    LogEvent('Floating_Call_Button_Clicked');
                                                    window.location.href = `tel:${phoneNumber}`;
                                                }}>{t("Call")} </span>
                                </button>
                            </div>
                        </div>
                    ))}
                </Slider>
                </div>
            </Container>
        </div>
    );
};

export default CommonDiseasesPage;

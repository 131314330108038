import React from "react";
import { Carousel } from "react-bootstrap";
import centercard1image from "../../assets/images/bannercardimages/Banner 1.webp";
import centercard2image from "../../assets/images/bannercardimages/Banner 2.webp";
import centercard3image from "../../assets/images/bannercardimages/Banner 4.webp";
import { FaPhoneAlt } from "react-icons/fa";
import { DecryptFunction } from "../../utils/cryptoFunction";
import { LogEvent } from "../../utils/firebase";

// import centercardimage from "../../assets/images/new_home_page/banner3 3.jpg";
import centercardimage from "../../assets/images/new_home_page/banner5.png";
// import centercardimage from "../../assets/images/new_home_page/Banner3 2.png";
// import { useTranslation } from 'react-i18next';
import { useTranslation } from "react-i18next";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import vetdoctor from "../../assets/images/consulation/landing page/doctor.png";
import herbal from "../../assets/images/consulation/landing page/3products.png";
import medicine from "../../assets/images/consulation/landing page/1product.png";
import { useNavigate } from "react-router-dom";

const YourComponent = () => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const textStyle = {
    fontSize: currentLang === "tm" ? "12px" : "15px",
    lineHeight: currentLang === "tm" ? "1.5" : "1.5",
    maxHeight: currentLang === "tm" ? "40px" : "",
    minHeight: currentLang === "tm" ? "40px" : "",
  };
  const navigate = useNavigate();

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    prevArrow: <MdOutlineArrowBackIos />,
    nextArrow: <MdOutlineArrowForwardIos />,
  };

  const productArray = [
    {
      id: 1,
      image_url: vetdoctor,
      medicine_name: {
        en: "Consult A Vet",
        tm: "உடனடி கால்நடை மருத்துவர் ஆலோசனை",
        mr: "डॉक्टरांचा त्वरित सल्ला",
      },
      discription: {
        en: "Buy Herbal Medicines",
        tm: "ஒரு நிமிடத்திற்குள் கால்நடை மருத்துவரிடம் பேசுங்கள்",
        mr: "एका मिनिटात पशुवैद्यांशी बोला",
      },
      redirect_url: "/consultation",
      EventName: "Home_OurServices_Consult",
    },
    {
      id: 2,
      image_url: herbal,
      medicine_name: {
        en: "Buy Herbal Medicines",
        tm: "நோய்கள் & மூலிகை மருத்துவம்",
        mr: "रोग आणि हर्बल उपचार",
      },
      discription: {
        en: "Right solution for your sick animal",
        tm: "நோய்வாய்ப்பட்ட கால்நடைகளுக்கு சரியான தீர்வு",
        mr: "तुमच्या आजारी जनावरासाठी योग्य उपाय",
      },
      redirect_url: "/products?category=herbal",
      EventName: "Home_OurServices_Herbal",
    },
    {
      id: 3,
      image_url: medicine,
      medicine_name: {
        en: "Increase Milk Production",
        tm: "பால் உற்பத்தியை அதிகரிக்க",
        mr: "दूध उत्पादन वाढवा",
      },
      discription: {
        en: "Choose right feed for your dairy animal",
        tm: "சரியான தீவனத்தை உங்கள் கால்நடைகளுக்கு தேர்வு செய்யுங்கள்",
        mr: "तुमच्या दुग्धजन्य प्राण्यांसाठी योग्य खाद्य निवडा",
      },
      redirect_url: "/consultation",
      EventName: "Home_OurServices_MilkProduction",
    },
  ];

  return (
    <>
      <div
        className="new_home_cardimage"
        style={{ width: "100%", height: "auto" }}
      >
        <div className="first_card">
          <img
            src={centercardimage}
            alt="Description of Image"
            style={{ width: "100%", height: "auto" }}
          />
        </div>
        <div
          className="container second_card hide-below-1023"
          style={{
            display: "flex",
            flexWrap: "wrap",
            backgroundColor: "#F6F7FF",
            borderRadius: "16px",
          }}
        >
          {productArray.map((product) => (
            <div
              key={product.id}
              className="best-seller-container rounded"
              onClick={() => {
                LogEvent(product?.EventName);
                navigate(product?.redirect_url);
              }}
              style={{ borderRadius: "10px", overflow: "hidden" }} // Added to ensure border radius is applied to the container
            >
              {/* the small image in the center card */}
              <img
                src={product.image_url}
                alt={product.medicine_name}
                className="img-thumbnail-cls-for-services"
                style={{
                  height: "160px",
                  backgroundColor: "#D9D9D9",
                  width: "178px",
                  borderRadius: "10px 10px 0px 0px    ",
                }} // Removed borderRadius from here
              />

              {/* text below each image */}
              <div className="container cursor-pointer">
                <h4
                  className="best-seller-name"
                  style={{
                    ...textStyle,
                    fontWeight: "bold",
                    color: "#232c63",
                    textAlign: "center",
                  }}
                >
                  {product.medicine_name[i18n.language]}
                </h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default YourComponent;
